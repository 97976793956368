<template>
  <v-card class="mx-auto mt-5 mb-5" elevation="0" max-width="600">
    <v-row align="center" justify="space-around">
      <v-btn
        outlined
        color="primary"
        class="my-3"
        style="width: 80%"
        @click="download_reg"
        :loading="regLoading"
      >
        <v-icon>mdi-file-pdf-box</v-icon>Registration List
      </v-btn>
    </v-row>
    <v-row align="center" justify="space-around">
      <v-btn
        outlined
        color="primary"
        :loading="regListLoading"
        class="my-3"
        @click="download_reg_list"
        style="width: 80%"
      >
        <v-icon>mdi-table-arrow-down</v-icon>Registration List (excel)
      </v-btn>
    </v-row>
    <v-row align="center" justify="space-around">
      <appointment-card-dialog
        :event="event"
        :seminar_id="seminar_id"
      ></appointment-card-dialog>
    </v-row>
    <v-row align="center" justify="space-around">
      <v-btn
        outlined
        color="primary"
        class="my-3"
        style="width: 80%"
        @click="download_nameplate"
        :loading="nameplateLoading"
      >
        <v-icon>mdi-file-pdf-box</v-icon>Nameplates (Avery 5305)
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import AppointmentCardDialog from "./AppointmentCardDialog.vue";
import axiosService from "@/services/axios.service.js";
export default {
  name: "EventMaterials",
  components: {
    AppointmentCardDialog,
  },
  mixins: [axiosService],
  props: {
    event: Object,
    seminar_id: Number,
  },

  data() {
    return {
      regListLoading: false,
      regLoading: false,
      nameplateLoading: false,
    };
  },
  methods: {
    async download_reg_list() {
      this.regListLoading = true;
      this.dl_event_list(this.event.id);
      this.regListLoading = false;
    },
    async download_reg() {
      this.regLoading = true;
      await this.registration_list(this.seminar_id, this.event.id);
      this.regLoading = false;
    },
    async download_nameplate() {
      this.nameplateLoading = true;
      await this.nameplate_list(this.seminar_id, this.event.id);
      this.nameplateLoading = false;
    },
  },
  computed: {},
  mounted() {},
};
</script>
