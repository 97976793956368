var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2 row-pointer",staticStyle:{"width":"100%"},attrs:{"headers":_vm.householdHeaders,"items":_vm.households,"single-expand":true,"expanded":_vm.expanded,"show-expand":"","hide-default-footer":"","disable-pagination":""},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clicked},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Households")]),_c('v-spacer'),_c('new-household-dialog')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"mt-2 mb-2",attrs:{"flat":""}},[_c('v-card-text',[_c('household-detail',{attrs:{"household":item}}),_c('household-notes',{attrs:{"household":item}})],1)],1)],1)]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"overflow-wrap":"break-word"}},[_vm._v(_vm._s(item.email))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNumber(item.phone))+" ")]}},{key:"item.lead_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"titlecase"},[_vm._v(_vm._s(item.lead_type))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"titlecase"},[_vm._v(_vm._s(item.status))])]}},{key:"item.clientname",fn:function(ref){
var item = ref.item;
return _vm._l((item.people),function(person,index){return _c('div',{key:index},[(person.primary || item.people.length == 1)?_c('div',[_vm._v(" "+_vm._s(person.name)+" ")]):_vm._e()])})}},{key:"item.spousename",fn:function(ref){
var item = ref.item;
return _vm._l((item.people),function(person,index){return _c('div',{key:index},[(!person.primary && item.people.length == 2)?_c('div',[_vm._v(" "+_vm._s(person.name || "(blank)")+" ")]):_vm._e()])})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }