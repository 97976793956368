<template>
  <v-data-table
    :headers="householdHeaders"
    :items="households"
    :single-expand="true"
    :expanded.sync="expanded"
    @click:row="clicked"
    show-expand
    style="width: 100%"
    class="elevation-2 row-pointer"
    hide-default-footer
    disable-pagination
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Households</v-toolbar-title>
        <v-spacer></v-spacer>
        <new-household-dialog></new-household-dialog>
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <!-- card start -->
        <v-card flat class="mt-2 mb-2">
          <v-card-text>
            <household-detail :household="item"></household-detail>
            <household-notes :household="item"></household-notes>
          </v-card-text>
        </v-card>
        <!-- card end -->
      </td>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.created="{ item }">
      {{ formatDate(item.created) }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.email="{ item }">
      <!-- TODO: Fix email column width while in tablet mode if email is very long -->
      <div style="overflow-wrap: break-word">{{ item.email }}</div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.phone="{ item }">
      {{ formatPhoneNumber(item.phone) }}
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.lead_type="{ item }">
      <div class="titlecase">{{ item.lead_type }}</div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.status="{ item }">
      <div class="titlecase">{{ item.status }}</div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.clientname="{ item }">
      <div v-for="(person, index) in item.people" :key="index">
        <div v-if="person.primary || item.people.length == 1">
          {{ person.name }}
        </div>
      </div>
    </template>
    <!-- eslint-disable-next-line -->
    <template v-slot:item.spousename="{ item }">
      <div v-for="(person, index) in item.people" :key="index">
        <div v-if="!person.primary && item.people.length == 2">
          {{ person.name || "(blank)" }}
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<style scoped>
.titlecase {
  text-transform: capitalize;
}
</style>

<script>
import HouseholdDetail from "./HouseholdDetail.vue";
import HouseholdNotes from "./HouseholdNotes.vue";
import NewHouseholdDialog from "./NewHouseholdDialog.vue";
import dayjs from "dayjs";

export default {
  name: "HouseholdTable",

  components: {
    HouseholdDetail,
    HouseholdNotes,
    NewHouseholdDialog,
  },
  props: {
    households: Array,
  },
  data() {
    return {
      expanded: [],
      householdHeaders: [
        {
          text: "Client Name",
          value: "clientname",
        },
        { text: "Spouse Name", value: "spousename" },
        { text: "Email", value: "email", width: "15%" },
        { text: "Phone", value: "phone" },
        { text: "Status", value: "status" },
        { text: "Lead Type", value: "lead_type" },
        { text: "Registered", value: "created" },
      ],

      tab: null,
      tabitems: ["Household Details", "Notes"],
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  methods: {
    clicked(value) {
      if (this.expanded.length == 0) {
        this.expanded.push(value);
      } else if (this.expanded.length == 1) {
        if (this.expanded[0] == value) {
          this.expanded = [];
        } else {
          this.expanded = [];
          this.expanded.push(value);
        }
      }
    },
    formatDate(date) {
      return dayjs(date).format("M/D/YY");
    },
    formatPhoneNumber(phoneNumberString) {
      if (!phoneNumberString) {
        return "";
      }
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return phoneNumberString;
    },
  },
};
</script>
