<template>
  <v-card class="mt-5 mb-5" elevation="0">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="event.capacity"
          style="max-width: 80%; margin-left: auto; margin-right: auto"
          class="justify-center"
          label="Capacity"
          outlined
          type="number"
        ></v-text-field>
        <div class="text-center">
          <v-btn color="primary" @click="btnUpdate">
            Update
          </v-btn>
        </div>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" md="6">
        <div class="text-center">
          <v-btn outlined color="error" @click="btnDisable">
            Disable New Registrations
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  mixins: [hasuraService],
  methods: {
    btnUpdate() {
      this.updateEventCapacity(this.event.id, this.event.capacity || 0);
      this.$store.commit("set_snackbar", "Event Capacity Updated");
    },
    btnDisable() {
      this.event.capacity = 0;
      this.updateEventCapacity(this.event.id, this.event.capacity);
      this.$store.commit("set_snackbar", "Event Registration Disabled");
    },
  },
  props: {
    event: Object,
  },
};
</script>
