<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        outlined
        color="primary"
        class="my-3 btn-fix"
        style="width: 80%"
      >
        <v-icon>mdi-file-pdf-box</v-icon>Appointment Cards
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Appointment Cards</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Time
                  </th>
                  <th class="text-left">
                    <v-select
                      v-model="timeslots.d1_day"
                      :items="days"
                      label="First Day"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </th>
                  <th class="text-left">
                    <v-select
                      v-model="timeslots.d2_day"
                      :items="days"
                      label="Second Day"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </th>
                  <th class="text-left">
                    <v-select
                      v-model="timeslots.d3_day"
                      :items="days"
                      label="Third Day"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </th>
                  <th class="text-left">
                    <v-select
                      v-model="timeslots.d4_day"
                      :items="days"
                      label="Fourth Day"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="x in 13" :key="x">
                  <td style="height: 35px">{{ time_fmt(x + 7) }}</td>
                  <td style="height: 35px" class="text-center">
                    <v-simple-checkbox
                      dense
                      v-model="timeslots[`d1_${x}`]"
                      color="primary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </td>
                  <td style="height: 35px" class="text-center">
                    <v-simple-checkbox
                      dense
                      v-model="timeslots[`d2_${x}`]"
                      color="primary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </td>
                  <td style="height: 35px" class="text-center">
                    <v-simple-checkbox
                      dense
                      v-model="timeslots[`d3_${x}`]"
                      color="primary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </td>
                  <td style="height: 35px" class="text-center">
                    <v-simple-checkbox
                      dense
                      v-model="timeslots[`d4_${x}`]"
                      color="primary"
                      :ripple="false"
                    ></v-simple-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="download_regcard"
          :loading="regCardLoading"
        >
          Generate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>
<script>
import axiosService from "@/services/axios.service.js";

export default {
  name: "HouseholdDetail",
  mixins: [axiosService],
  props: {
    event: Object,
    seminar_id: Number,
  },

  mounted() {},
  methods: {
    time_fmt(time) {
      if (time < 12) {
        return time + "am";
      } else if (time == 12) {
        return time + "pm";
      } else {
        return time - 12 + "pm";
      }
    },
    async download_regcard() {
      this.regCardLoading = true;

      await this.registration_cards(
        this.seminar_id,
        this.event.id,
        this.timeslots
      );
      this.regCardLoading = false;
    },
  },
  data() {
    return {
      dialog: false,
      regCardLoading: false,
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      timeslots: {
        d1_day: "Monday",
        d2_day: "Tuesday",
        d3_day: "Wednesday",
        d4_day: "Thursday",
        d1_1: true,
        d1_2: true,
        d1_3: true,
        d1_4: true,
        d1_5: true,
        d1_6: true,
        d1_7: true,
        d1_8: true,
        d1_9: true,
        d1_10: true,
        d1_11: true,
        d1_12: true,
        d1_13: true,
        d2_1: true,
        d2_2: true,
        d2_3: true,
        d2_4: true,
        d2_5: true,
        d2_6: true,
        d2_7: true,
        d2_8: true,
        d2_9: true,
        d2_10: true,
        d2_11: true,
        d2_12: true,
        d2_13: true,
        d3_1: true,
        d3_2: true,
        d3_3: true,
        d3_4: true,
        d3_5: true,
        d3_6: true,
        d3_7: true,
        d3_8: true,
        d3_9: true,
        d3_10: true,
        d3_11: true,
        d3_12: true,
        d3_13: true,
        d4_1: true,
        d4_2: true,
        d4_3: true,
        d4_4: true,
        d4_5: true,
        d4_6: true,
        d4_7: true,
        d4_8: true,
        d4_9: true,
        d4_10: true,
        d4_11: true,
        d4_12: true,
        d4_13: true,
      },
    };
  },
};
</script>
