<template>
  <v-data-table
    :headers="eventHeaders"
    :items="events"
    :single-expand="true"
    :expanded.sync="expanded"
    @click:row="clicked"
    show-expand
    style="width: 100%"
    class="elevation-2 row-pointer"
    hide-default-footer
    disable-pagination
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Events Materials</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn>Add Event Date</v-btn> -->
      </v-toolbar>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <!-- card start -->
        <v-card class="mt-2 mb-2">
          <v-tabs v-model="tab">
            <v-tabs-slider></v-tabs-slider>

            <v-tab v-for="tabitem in tabitems" :key="tabitem">
              {{ tabitem }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="tabitem in tabitems" :key="tabitem">
              <event-materials
                :seminar_id="seminar_id"
                :event="item"
                v-if="tabitem == 'Materials'"
              >
              </event-materials>
              <event-edit :event="item" v-if="tabitem == 'Edit'"></event-edit>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <!-- card end -->
      </td>
    </template>
  </v-data-table>
</template>

<script>
import EventEdit from "@/components/Seminar/EventEdit.vue";
import EventMaterials from "@/components/Seminar/EventMaterials.vue";

export default {
  name: "EventTable",
  components: {
    EventEdit,
    EventMaterials,
  },
  methods: {
    clicked(value) {
      if (this.expanded.length == 0) {
        this.expanded.push(value);
      } else if (this.expanded.length == 1) {
        if (this.expanded[0] == value) {
          this.expanded = [];
        } else {
          this.expanded = [];
          this.expanded.push(value);
        }
      }
    },
  },
  props: {
    events: Array,
    seminar_id: Number,
  },
  data() {
    return {
      expanded: [],
      singleExpand: false,
      eventHeaders: [
        {
          text: "Date",
          value: "date",
        },
        { text: "Count", value: "count" },
        { text: "Capacity", value: "capacity" },
      ],

      tab: null,
      tabitems: ["Materials", "Edit"],
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
};
</script>
